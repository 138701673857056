import { getBankResourceStarted, getBankResourceSuccess, getBankResourceError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { getDataResource } from '../../helpers/resources';
const getBankResource = () => {
    return (dispatch: any) => {
        dispatch(getBankResourceStarted());
        getDataResource('banks')
            .then((result) => {
                dispatch(getBankResourceSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getBankResourceError(e));
            });
    };
};
export default getBankResource;
