import { postData, getData } from '../../helpers/apiConnections';
import { getPostsStart, getPostsError, getPostsSuc } from './types';
import PostFilterInterface from '../../model_interfaces/PostFilterInterface';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
interface FilterObjetc {
    filters: PostFilterInterface;
}
const deleteVoidFields = (values?: PostFilterInterface) => {
    let filters: FilterObjetc = { filters: {} };
    if (values) {
        for (let key of Object.keys(values)) {
            filters.filters[`${key}`] = values[key];
        }
    }
    return filters;
};
const getPosts = (filter?: PostFilterInterface, state?: object) => {
    return (dispatch: any) => {
        dispatch(getPostsStart());
        if (filter) {
            filter = deleteVoidFields(filter);
            postData(`posts/filters`, filter)
                .then((result) => {
                    dispatch(getPostsSuc(result.data));
                })
                .catch((e) => {
                    toast(handleError(e));
                    return dispatch(getPostsError(e));
                });
        } else {
            if (state) {
                getData('posts', state)
                    .then((result) => {
                        dispatch(getPostsSuc(result.data));
                    })
                    .catch((e) => {
                        toast(handleError(e));
                        return dispatch(getPostsError(e));
                    });
            } else {
                getData('posts')
                    .then((result) => {
                        dispatch(getPostsSuc(result.data));
                    })
                    .catch((e) => {
                        toast(handleError(e));
                        return dispatch(getPostsError(e));
                    });
            }
        }
    };
};
export default getPosts;
