import { getAdminContractStart, getAdminContractSuccess, getAdminContractError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getAdminContract = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getAdminContractStart());
        getData(`admin_contracts/${id}`)
            .then((result) => {
                return dispatch(getAdminContractSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getAdminContractError(e));
            });
    };
};
export default getAdminContract;
