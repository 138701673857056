import { reproposePriceByAdminStart, reproposePriceByAdminSuccess, reproposePriceByAdminError } from './types';
import { postData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const reproposePriceByAdmin = (data: any, id: number) => {
    return (dispatch: any) => {
        dispatch(reproposePriceByAdminStart());

        const renewal_period = {
            renewal_period: {
                price: data.proposed_price,
                reason: data.reasonNewPrice,
                currency: data.currencyIsoValue,
            },
        };

        postData(`renewal_periods/${id}/repropose_price`, renewal_period)
            .then((result) => {
                dispatch(reproposePriceByAdminSuccess());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(reproposePriceByAdminError(e));
            });
    };
};
export default reproposePriceByAdmin;
