import { getLocationDataStart, getLocationDataSuccess, getLocationDataError } from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getLocationUser = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getLocationDataStart());

        axios
            .get(`https://ipapi.co/json/`)
            .then((result: any) => {
                dispatch(getLocationDataSuccess(result.data));
            })
            .catch((e: any) => {
                toast(handleError(e));
                return dispatch(getLocationDataError(e));
            });
    };
};
export default getLocationUser;
