export const reproposePriceByAdminStart = () => {
    return {
        type: 'REPROPOSE_PRICE_STARTED',
    };
};

export const reproposePriceByAdminSuccess = () => {
    return {
        type: 'REPROPOSE_PRICE_SUCCESS',
    };
};

export const reproposePriceByAdminError = (error: any) => {
    return {
        type: 'REPROPOSE_PRICE_ERROR',
        payload: error,
    };
};
