import React from 'react';
import intl from 'react-intl-universal';
import { CircularLoading } from '..';

interface HandleFetchProps {
    fetching: Array<any>;
    children?: any;
    render?: any;
}

const HandleFetch = ({ fetching, children, render }: HandleFetchProps) => {
    const conditionsLoading = ['LOADING', 'FETCHING'];
    const conditionsLoaded = ['LOADED', 'FETCHED'];
    return (
        <>
            {fetching.some((fetch) => conditionsLoading.includes(fetch)) && <CircularLoading />}
            {fetching.every((fetch) => conditionsLoaded.some((condition) => fetch.includes(condition))) &&
                (render || children)}
            {fetching.some((fetch) => fetch.includes('ERROR')) && <p>{intl.get('ERROR')}</p>}
        </>
    );
};

export default HandleFetch;
