import {
    rejectLeaseRenewalByAdminStart,
    rejectLeaseRenewalByAdminSuccess,
    rejectLeaseRenewalByAdminError,
} from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const rejectLeaseRenewalByAdmin = (data: any, id: number) => {
    return (dispatch: any) => {
        dispatch(rejectLeaseRenewalByAdminStart());
        const renewal_period = {
            renewal_period: {
                reason: data.reasonNoRenewal,
            },
        };
        postData(`renewal_periods/${id}/reject`, renewal_period)
            .then((result) => {
                dispatch(rejectLeaseRenewalByAdminSuccess());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(rejectLeaseRenewalByAdminError(e));
            });
    };
};
export default rejectLeaseRenewalByAdmin;
