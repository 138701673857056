import React from 'react';
import intl from 'react-intl-universal';

interface ButtonRedirectProps {
    route: string;
    label: string;
}

const ButtonRedirect = ({ route, label }: ButtonRedirectProps) => {
    return (
        <button
            className='button-fourth col-12'
            onClick={(e: any) => (window.location.href = window.location.origin + `${route}`)}
        >
            {intl.get(label.toUpperCase())}
        </button>
    );
};

export default ButtonRedirect;
