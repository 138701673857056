export const getUserDataStart = () => {
  return {
    type: 'GET_USER_VALIDATION_DATA_STARTED',
  }
}

export const getUserDataSuccess = (user: any) => {
  return {
    type: 'GET_USER_VALIDATION_DATA_SUCCESS',
    payload: { user },
  }
}

export const setChangeConsigneeData = (renter_profiles: any) => {
  return {
    type: 'CHANGE_CONSIGNEE',
    consignees: { renter_profiles },
  }
}

export const getUserDataError = (error: any) => {
  return {
    type: 'GET_USER_VALIDATION_DATA_ERROR',
    payload: {
      error,
    },
  }
}
