import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

interface Option {
    label: string;
}
/** Here it is defined the type of the props for the interface "RadioButtonProps"
 * @typedef RadioButtonProps
 * @type {(function|object|string)}
 * @property {array} options - is an array.
 * @property {string} id - is a string.
 * @property {string} label - is a string.
 * @property {boolean} disabled - is a boolean.
 * @property {string} info - is a string.
 * @property {string} classNameLabel - is a string.
 * @property {string} classNameLabelInput - is a string.
 * @property {string} classNameInputContainer - is a string.
 * @property {string} classInfo - is a string.
 * @property {number} keyNumber - is a number.
 * @property {function} onClick - is a function.
 */
interface RadioButtonProps {
    options: Array<Option>;
    id: string;
    label?: string;
    className?: string;
    disabled: boolean;
    info?: string;
    classNameLabel?: string;
    classNameLabelInput?: string;
    classNameInput?: string;
    classNameInputContainer?: string;
    classInfo?: string;
    keyNumber?: number;
    onClick?: any;
}

/**
 * ButtonsActions is a function that shows the property details
 *@function
 *@param {RadioButtonTangoLinkTypes} options - array with the option to show
 *@param {RadioButtonTangoLinkTypes} id - id for the input
 *@param {RadioButtonTangoLinkTypes} className  - class of the principal container
 *@param {RadioButtonTangoLinkTypes} disabled  - if the input is disabled
 *@param {RadioButtonTangoLinkTypes} info - info addtional to describe the input
 *@param {RadioButtonTangoLinkTypes} classNameLabel - class of the label
 *@param {RadioButtonTangoLinkTypes} classNameLabelInput  -class of the input label
 *@param {RadioButtonTangoLinkTypes} classNameInput  - class of the input
 *@param {RadioButtonTangoLinkTypes} classNameInputContainer  - class of the input container
 *@param {RadioButtonTangoLinkTypes} classInfo  - class of the label info
 *@param {RadioButtonTangoLinkTypes} keyNumber  - identifier
 *@param {RadioButtonTangoLinkTypes} onClick  - this function makes the click link
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const RadioButtonTangoLink = ({
    options,
    id,
    label,
    className,
    disabled,
    info,
    classNameLabel = 'col-md-4 pl-0 col-12',
    classNameLabelInput,
    classNameInput,
    classNameInputContainer = 'col-md-8 col-12 plm-0',
    classInfo,
    keyNumber = 0,
    onClick,
}: RadioButtonProps) => {
    return (
        <>
            <div className={`radio-button-link-container d-flex align-items-start row ${className}`}>
                {label !== undefined && (
                    <div className={classNameLabel}>
                        <span className={`col-12 span-text color-secondary ${classNameLabelInput}`}>
                            {intl.getHTML(label)}
                        </span>
                        <p className={`${classInfo} p-info-label`}>{intl.getHTML(`${info}`)}</p>
                    </div>
                )}
                <div className={'row radio-button-link'}>
                    {options.map((option, index) => {
                        const classByBoolean = Number(option.label) || parseInt(option.label) === 0;

                        return (
                            <div key={`opt-${index}-${label}`} className={'col-md-6 p-0 pr-4'} onClick={onClick}>
                                <input
                                    type='radio'
                                    id={`radio-${option.label}-${id}${keyNumber ? `-${keyNumber}` : ''}`}
                                    className={'radio-string'}
                                    name={`${id}`}
                                    disabled={disabled}
                                />
                                <label
                                    className={`${
                                        disabled ? 'button-secondary-disabled-string' : 'button-secondary-string'
                                    }`}
                                    style={{ width: !classByBoolean ? '100%' : '' }}
                                    htmlFor={`radio-${option.label}-${id}${keyNumber > 0 ? `-${keyNumber}` : ''}`}
                                >
                                    {intl.get(`${option.label}`)}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default RadioButtonTangoLink;
