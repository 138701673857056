export const updateUnitStart = () => {
    return {
        type: 'UPDATE_UNIT_STARTED',
    };
};
export const updateUnitSuccess = (result: any) => {
    return {
        type: 'UPDATE_UNIT_SUCCESS',
        payload: result,
    };
};

export const updateUnitError = (error: any) => {
    return {
        type: 'UPDATE_UNIT_ERROR',
        payload: {
            error,
        },
    };
};
