export const getCountriesStart = () => {
    return {
        type: 'GET_COUNTRIES_STARTED',
    };
};

export const getCountriesSuc = (countries: any) => {
    return {
        type: 'GET_COUNTRIES_SUCCESS',
        payload: countries,
    };
};

export const getCountriesError = (error: any) => {
    return {
        type: 'GET_COUNTRIES_ERROR',
        payload: {
            error,
        },
    };
};
