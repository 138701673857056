export const getContractsStart = () => {
    return {
        type: 'GET_CONTRACTS_STARTED',
    };
};

export const getContractsSuccess = (result: any) => {
    return {
        type: 'GET_CONTRACTS_SUCCESS',
        payload: { result },
    };
};
export const getContractsError = (error: any) => {
    return {
        type: 'GET_CONTRACTS_ERROR',
        payload: {
            error,
        },
    };
};
