export const getRentersPendingStart = () => {
    return {
        type: 'GET_RENTERS_PENDING_STARTED',
    };
};

export const getRentersPendingSuccess = (result: any) => {
    return {
        type: 'GET_RENTERS_PENDING_SUCCESS',
        payload: { result },
    };
};
export const getRentersPendingError = (error: any) => {
    return {
        type: 'GET_RENTERS_PENDING_ERROR',
        payload: {
            error,
        },
    };
};
