export const listMenu = [
  { route: '/users', name: 'USERS', state: 'users' },
  { route: '/renters', name: 'RENTERS' },
  { route: '/posts', name: 'TITLE_PROPERTIES' },
  { route: '/visits', name: 'TITLE_VISITS' },
  { route: '/payments', name: 'PAYMENTS' },
  { route: '/contracts', name: 'CONTRACTS' },
  { route: '/pending-vouchers', name: 'PENDING_VOUCHERS' },
  { route: '/campaigns', name: 'CAMPAIGNS'}
]
