import { getRentersValidateStart, getRentersValidateSuccess, getRentersValidateError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRentersValidate = (id?: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getRentersValidateStart());
        getData(`users/renters?renter_state=validated`)
            .then((result) => {
                return dispatch(getRentersValidateSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRentersValidateError(e));
            });
    };
};
export default getRentersValidate;
