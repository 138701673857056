export const proposePriceByAdminStart = () => {
    return {
        type: 'PROPOSE_PRICE_STARTED',
    };
};

export const proposePriceByAdminSuccess = () => {
    return {
        type: 'PROPOSE_PRICE_SUCCESS',
    };
};

export const proposePriceByAdminError = (error: any) => {
    return {
        type: 'PROPOSE_PRICE_ERROR',
        payload: error,
    };
};
