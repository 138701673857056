import { getContractsStart, getContractsSuccess, getContractsError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getContract = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getContractsStart());
        getData('contracts')
            .then((result) => {
                return dispatch(getContractsSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getContractsError(e));
            });
    };
};
export default getContract;
