export const updateUnitVersionStart = () => {
    return {
        type: 'UPDATE_UNIT_VERSION_STARTED',
    };
};
export const updateUnitVersionSuccess = (result: any) => {
    return {
        type: 'UPDATE_UNIT_VERSION_SUCCESS',
        payload: result,
    };
};

export const updateUnitVersionError = (error: any) => {
    return {
        type: 'UPDATE_UNIT_VERSION_ERROR',
        payload: {
            error,
        },
    };
};
