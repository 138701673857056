export const getBankResourceStarted = () => {
    return {
        type: 'GET_BANK_RESOURCE_STARTED',
    };
};

export const getBankResourceSuccess = (data: any) => {
    return {
        type: 'GET_BANK_RESOURCE_SUCCESS',
        payload: { data },
    };
};

export const getBankResourceError = (error: any) => {
    return {
        type: 'GET_BANK_RESOURCE_ERROR',
        payload: {
            error,
        },
    };
};
