import React from 'react';
import intl from 'react-intl-universal';

interface PromotionCardProps {
    promotion: any;
}

interface CardPlanDetailsProps {
    kind: string;
    active: boolean;
    possibleDiscounts: string[];
    duration?: any;
    discountList: { name: string; value: number }[];
}

const CardPlanDetails = ({ kind, active, possibleDiscounts, duration, discountList = [] }: CardPlanDetailsProps) => {
    return active ? (
        <div className='card-body promotion-card-plan-detail'>
            <div className='promotion-card-plan-section'>
                <div className='row'>
                    <section>
                        <dl className=''>
                            <dt className='plan-title'>{intl.get('PLAN')}</dt>
                            <dd className='plan-name'>{intl.get(kind)}</dd>
                        </dl>
                    </section>
                    <section>
                        <dl className=''>
                            {duration && (
                                <>
                                    <dt className='plan-duration'>{intl.get('DURATION')}</dt>
                                    <dd className='plan-duration'>
                                        {duration} {intl.get('MONTHS')}
                                    </dd>
                                </>
                            )}
                        </dl>
                    </section>
                </div>
                <div className='row'>
                    <section>
                        <dl className='discount-list'>
                            {discountList.map((el) => {
                                return (
                                    el.value &&
                                    possibleDiscounts.includes(el.name) && (
                                        <>
                                            <dt>{intl.get(el.name)}</dt>
                                            <dd>{el.value} %</dd>
                                        </>
                                    )
                                );
                            })}
                        </dl>
                    </section>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

const CardPromotions = ({ promotion }: PromotionCardProps) => {
    const id = promotion.id;
    const {
        name,
        description,
        kind,
        address,
        starts_at,
        ends_at,
        state_to_show,
        administration_fee_new_discount,
        collection_fee_new_discount_owner,
        collection_fee_new_discount_renter,
        new_discount_duration,
        administration_fee_registered_discount,
        registered_discount_duration,
        collection_fee_brokerage_discount_owner,
        collection_fee_brokerage_discount_renter,
    } = promotion.attributes;

    const planFullTango =
        (administration_fee_new_discount && new_discount_duration) ||
        collection_fee_new_discount_owner ||
        collection_fee_new_discount_renter;

    const planBrokerage = collection_fee_brokerage_discount_owner || collection_fee_brokerage_discount_renter;

    const planAdministered = administration_fee_registered_discount && registered_discount_duration;

    const hasPlan = planFullTango || planBrokerage || planAdministered;

    const planNameKey =
        (planFullTango && 'PLAN_FULL') || (planBrokerage && 'BROKERAGE') || (planAdministered && 'ADMINISTERED');
    const planName = intl.get(planNameKey);
    const duration = ' ??';

    const discountList = [
        { name: 'ADMINISTRATION_FEE_NEW_DISCOUNT', value: administration_fee_new_discount },
        { name: 'ADMINISTRATION_FEE_REGISTERED_DISCOUNT', value: administration_fee_registered_discount },
        { name: 'OWNER_BROKERAGE_FEE_NEW_DISCOUNT', value: collection_fee_new_discount_owner },
        { name: 'RENTER_BROKERAGE_FEE_NEW_DISCOUNT', value: collection_fee_new_discount_renter },
        { name: 'OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT', value: collection_fee_brokerage_discount_owner },
        { name: 'RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT', value: collection_fee_brokerage_discount_renter },
    ];

    const plans = [
        {
            kind: 'PLAN_FULL',
            active: planFullTango,
            possibleDiscounts: [
                'ADMINISTRATION_FEE_NEW_DISCOUNT',
                'OWNER_BROKERAGE_FEE_NEW_DISCOUNT',
                'RENTER_BROKERAGE_FEE_NEW_DISCOUNT',
            ],
            duration: new_discount_duration,
            discountList,
        },
        {
            kind: 'BROKERAGE',
            active: planBrokerage,
            possibleDiscounts: ['OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT', 'RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT'],
            duration: null,
            discountList,
        },
        {
            kind: 'ADMINISTERED',
            active: planAdministered,
            possibleDiscounts: ['ADMINISTRATION_FEE_REGISTERED_DISCOUNT'],
            duration: registered_discount_duration,
            discountList,
        },
    ];

    return (
        <div key={promotion.id} className='promotion-card'>
            <div className='card-body'>
                <div className='row'>
                    <section>
                        <h2 className='promotion-name'>{name}</h2>
                        <p className='promotion-description'>{description}</p>
                        <p className='promotion-plan-address'>{address}</p>
                    </section>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <section>
                        <dl className=''>
                            <dt>{intl.get('KIND')}</dt>
                            <dd>{intl.get(kind.toUpperCase())}</dd>
                        </dl>
                    </section>
                    <section>
                        <dl>
                            <dt className='inline-dl-title'>{intl.get('STATUS')}</dt>
                            <dd className='inline-dl-value'>{intl.get(`PROMOTION_${state_to_show.toUpperCase()}`)}</dd>
                        </dl>
                    </section>
                </div>
                <div className='row'>
                    <section>
                        <dl>
                            <dt className='inline-dl-title'>{intl.get('START_DATE')}</dt>
                            <dd className='inline-dl-value'>{starts_at}</dd>
                        </dl>
                    </section>
                    <section>
                        <dl>
                            <dt className='inline-dl-title'>{intl.get('END_DATE')}</dt>
                            <dd className='inline-dl-value'>{ends_at}</dd>
                        </dl>
                    </section>
                </div>
            </div>
            {hasPlan && <hr />}
            {plans.map((opts) => (
                <CardPlanDetails {...opts} />
            ))}
        </div>
    );
};

export default CardPromotions;
