import {
  getUserDataStart,
  getUserDataSuccess,
  getUserDataError,
  setChangeConsigneeData,
} from './types'
import { getData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
import { isRoleAdmin } from '../../../helpers/roleComparison/index';
const getUserData = (userId: string) => {
  return (dispatch: any, getState: any) => {
    const renterData = isRoleAdmin ? '/renter_data' : ''
    dispatch(getUserDataStart())
    getData(`users/${userId}${renterData}`)
      .then((result: any) => {
        dispatch(
          setChangeConsigneeData(result.data.data.attributes.renter_profiles),
        )
        dispatch(getUserDataSuccess(result.data))
        
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(getUserDataError(e))
      })
  }
}
export default getUserData
