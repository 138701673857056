import { publishUnitVersionStart, publishUnitVersionSuccess, publishUnitVersionError } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
import { getUnitVersion } from '..';
import { isRoleAdmin } from '../../helpers/roleComparison/index';

const publishUnitVersion = (id: number) => {
    return (dispatch: any) => {
        dispatch(publishUnitVersionStart());
        putData(`unit_versions/${id}/publish`)
            .then((result) => {
                toast(intl.get('DATA_SAVED'));
                dispatch(publishUnitVersionSuccess(result.data));
                isRoleAdmin && dispatch(getUnitVersion(id));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(publishUnitVersionError(e));
            });
    };
};

export default publishUnitVersion;
