export const getRentersValidateStart = () => {
    return {
        type: 'GET_RENTERS_VALIDATE_STARTED',
    };
};

export const getRentersValidateSuccess = (result: any) => {
    return {
        type: 'GET_RENTERS_VALIDATE_SUCCESS',
        payload: { result },
    };
};
export const getRentersValidateError = (error: any) => {
    return {
        type: 'GET_RENTERS_VALIDATE_ERROR',
        payload: {
            error,
        },
    };
};
