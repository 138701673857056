import { getDataResource } from '../../../tango-react-base/helpers/resources';
import { getCountriesStart, getCountriesError, getCountriesSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCountries = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getCountriesStart());
        getDataResource(`countries`)
            .then((result) => {
                dispatch(getCountriesSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getCountriesError(e));
            });
    };
};
export default getCountries;
