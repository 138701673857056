import { updateUnitStart, updateUnitSuccess, updateUnitError } from './types';
import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
import { getPost } from '../';
import { isRoleAdmin } from '../../helpers/roleComparison/index';

const updateUnit = (id: number, values: any) => {
    return (dispatch: any) => {
        dispatch(updateUnitStart());
        putData(`posts/${id}`, values)
            .then((result) => {
                toast(intl.get('DATA_SAVED'));
                dispatch(updateUnitSuccess(result.data));
                isRoleAdmin && dispatch(getPost(id));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updateUnitError(e));
            });
    };
};

export default updateUnit;
