import { getRentersPendingStart, getRentersPendingSuccess, getRentersPendingError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRentersPending = (id?: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getRentersPendingStart());
        getData(`users/renters?renter_state=pending`)
            .then((result) => {
                return dispatch(getRentersPendingSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRentersPendingError(e));
            });
    };
};
export default getRentersPending;
