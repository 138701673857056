import { proposePriceByAdminStart, proposePriceByAdminSuccess, proposePriceByAdminError } from './types';
import { postData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const proposePriceByAdmin = (data: any, id: number) => {
    return (dispatch: any) => {
        dispatch(proposePriceByAdminStart());

        const renewal_period = {
            renewal_period: {
                price: data.proposed_price,
                reason: data.reasonNewPrice,
                currency: data.currencyIsoValue,
            },
        };

        postData(`renewal_periods/${id}/propose_price`, renewal_period)
            .then((result) => {
                dispatch(proposePriceByAdminSuccess());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(proposePriceByAdminError(e));
            });
    };
};
export default proposePriceByAdmin;
