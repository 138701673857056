export const rejectLeaseRenewalByAdminStart = () => {
    return {
        type: 'REJECT_LEASE_RENEWAL_STARTED',
    };
};

export const rejectLeaseRenewalByAdminSuccess = () => {
    return {
        type: 'REJECT_LEASE_RENEWAL_SUCCESS',
    };
};

export const rejectLeaseRenewalByAdminError = (error: any) => {
    return {
        type: 'REJECT_LEASE_RENEWAL_ERROR',
        payload: error,
    };
};
