import { updateUnitVersionStart, updateUnitVersionSuccess, updateUnitVersionError } from './types';
import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';

const updateUnitVersion = (id: number, values: any) => {
    let queryObject: any = { unit_version: {}, post: {} };
    for (const key in values) {
        if (Array.isArray(values[key])) {
            if (values[key].length !== 0) {
                values[key] = values[key].reduce((total: string, actual: string) => total + `,${actual}`);
            }
        }
        if (key === 'estimate_price_amount') {
            queryObject['post']['price_amount'] = values[key];
            queryObject['unit_version'][`${key}`] = values[key];
        } else if (key === 'estimate_price_currency') {
            queryObject['post']['price_currency'] = values[key];
            queryObject['unit_version'][`${key}`] = values[key];
        } else if (
            values[key] ||
            values[key] === 0 ||
            values[key] === null ||
            values[key] === '' ||
            values[key] === []
        ) {
            let name = key;
            queryObject['unit_version'][`${name}`] = values[key];
        }
    }

    return (dispatch: any) => {
        dispatch(updateUnitVersionStart());
        putData(`unit_versions/${id}`, queryObject)
            .then((result) => {
                toast(intl.get('DATA_SAVED'));
                dispatch(updateUnitVersionSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updateUnitVersionError(e));
            });
    };
};

export default updateUnitVersion;
