export const getPostsError = (error: any) => {
    return {
        type: 'GET_POSTS_ERROR',
        payload: {
            error,
        },
    };
};

export const getPostsStart = () => {
    return {
        type: 'GET_POSTS_STARTED',
    };
};

export const getPostsSuc = (posts: any) => {
    return {
        type: 'GET_POSTS_SUCCESS',
        payload: {
            posts,
        },
    };
};
